.section-2 {
  /* background: linear-gradient(to bottom, #000428, #004683); */
  /* animation: background-color 20s; */
  padding: 34px 22px;
  margin: 0px 0px 0px 0px;
  background-color: white;
}

.container .col-lg-4 {
  display: flex;
  justify-content: center;
}

.card {
  width: 300px;
  height: 300px;
  transform-style: preserve-3d;
  perspective: 500px;
  border: none;
  background-color: inherit;
}

.card .face {
  position: absolute;
  color: #000;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.06);
  transform-style: preserve-3d;
  transition: 0.7s;
  backface-visibility: hidden;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.card .face.front-face,
.card .face.back-face {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card .face.front-face .profile {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.card .face.front-face .name {
  letter-spacing: 2px;
}

.card .face.front-face .designation {
  font-size: 0.8rem;
  color: #000;
  letter-spacing: 0.8px;
}

.card:hover .face.front-face {
  transform: rotateY(180deg);
}

.card .face.back-face {
  position: absolute;
  background: rgba(255, 255, 255, 0.06);
  transform: rotateY(180deg);
  padding: 20px 30px;
  text-align: center;
  user-select: none;
}

.card .face.back-face .fa-quote-left {
  position: absolute;
  top: 25px;
  left: 25px;
  font-size: 1.2rem;
}

.card .face.back-face .fa-quote-right {
  position: absolute;
  bottom: 35px;
  right: 25px;
  font-size: 1.2rem;
}

.card:hover .face.back-face {
  transform: rotateY(360deg);
}

@media screen and (max-width: 990px) {
  .card {
    margin: 22px 0px;
  }
}

.testimonialHeadText {
  text-align: center;
  margin: 0px 0px 32px 0px;
}
