@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Rubik", sans-serif;
}

*,
html,
body {
  scrollbar-width: none;
  scroll-behavior: smooth !;
  background-image: "linear-gradient(198deg, #bba9e2aa 0%, #ffffff 100%)";
}

@media only screen and (max-width: 768px) {
  HTML,
  body {
    width: 100%;
    overflow-x: hidden;
  }
}

html,
body {
  width: 100%;
  overflow-x: hidden;
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

.text-gradient {
  font-weight: 800;
  color: darkblue;
}

@supports (--css: variables) {
  .text-gradient {
    background-image: linear-gradient(
      to right,
      #9d50bb 0%,
      #6e48aa 51%,
      #9d50bb 100%
    );
    color: transparent;
    background-clip: text;
  }

  .text-gradient::selection {
    color: white;
    background: darkorchid;
  }
}
