.adminMenuList ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.adminMenuList ul li {
  padding: 8px 12px;
}

.adminMenuList ul li a {
  text-decoration: none;
  color: black;
}
.adminMenu {
  width: 400px;
}
.adminSidebar {
  height: 80vh;
}

.ps-sidebar-container {
  border-radius: 4px !important;
}