.contact-home-bg {
  background-image: url(../assets/images/contact/c4.jpg);
  min-height: 500;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px;
}

.contact-home-content-parent {
  background-color: rgba(0, 0, 0, 0.6);
  min-height: 500px;
}

/* @media (max-width: 1200) and (min-width: 0px) {
  .contact-home-bg {
    height: 850px;
  }

  .contact-home-content-parent {
    height: 850px;
  }
} */

.contact-home-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 80px;
}

.contact-home-content .content-left {
  background-color: #a886f3;
  color: white;
  width: 380px;
  /* flex-grow: 1; */
  display: flex;
  flex-direction: column;
  padding: 34px;
  margin: 34px 0px 0px 0px;
}

.contact-home-content .content-right {
  flex-grow: 2;
  padding: 44px;
}

.instagram-icon,
.linkedin-icon,
.whatsapp-icon,
.email-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
}

.instagram-icon > a,
.linkedin-icon > a,
.whatsapp-icon > a,
.email-icon > a {
  color: white;
}

.content-right .content-right-icons {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 44px;
}
