.order-card {
  font-size: 1.2rem;
  padding: 0px 1.8rem;
}

@media (max-width: 768px) {
  .order-info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .order-info div {
    display: flex;
    justify-content: space-between;
  }

  .order-info div.multi-row {
    flex-direction: column;
    gap: 0.5rem;
  }
}

@media (min-width: 768px) {
  .order-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
  .order-info div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 900px) {
  .order-card {
    font-size: 1.05rem;
  }
}

@media (max-width: 576px) {
  .order-card {
    font-size: 0.9rem;
    padding: 1rem;
  }
}

@media (max-width: 380px) {
  .orderId,
  .razorpayId {
    display: flex;
    flex-direction: column;
  }

  .orderId p,
  .razorpayId p {
    margin: 0;
  }

  .order-card {
    font-size: 0.85rem;
    padding: 1rem;
  }
}

@media (max-width: 335px) {
  .paymentId,
  .orderCreatedAt {
    display: flex;
    flex-direction: column;
  }

  .paymentId p,
  .orderCreatedAt p {
    margin: 0;
  }
}
