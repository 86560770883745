.course-container {
  padding: 0;
  background-image: linear-gradient(198deg, #bba9e2aa 0%, #ffffff 100%);
}

.course-banner-section {
  min-height: 700px;
  color: rgb(255, 255, 255);
  width: 100%;
}

.course-banner-right img {
  width: 100%;
  height: 450px;
}

.course-banner-left p {
  font-size: 2.8rem;
  line-height: 50px;
}

.course-banner-left {
  padding: 0px 2vw;
}

@media only screen and (max-width: 400px) {
  .course-banner-left p {
    font-size: 11vw;
  }

  .course-banner-section {
    gap: 0px !important;
  }
}

@media only screen and (max-width : 768) {
  .course-card {
    width: 400px;
  }
}

.card-img:hover img {
  transform: scale(1.1);
  transition-property: all;
  transition-duration: 0.6s;
}

/* kdhughve */

.courseCardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px;
  text-align: center;
  margin: 66px 0px 44px 0px;
}

.courseCard {
  width: 350px;
  padding: 34px 18px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  display: flex;
  flex-direction: column;
  transition: all;
  overflow: hidden;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

/* rgb(72 46 95 / 25%) 0px 13px 54px 8px, rgb(67 12 117 / 55%) 0px 8px 21px 3px */

.courseCard:hover {
  transform: perspective(25cm) rotateX(10deg) rotateY(10deg) !important;
}

.courseCard h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 12px 0px;
}

.courseCard p {
  font-size: 15px;
  font-family: "poppins", "sans-serif";
  color: black;
  margin: 12px 0px;
}

.courseCard .icon {
  width: 66px;
  height: 66px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 22px;
  margin: 12px auto;
}

.courseSection {
  background: url(../assets/images/home/bg-section.webp);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;
  background-size: cover;
  background-attachment: scroll;
  border: rgb(89, 191, 255);
}

.courseHeading {
  line-height: 52px;
  margin-bottom: 80px;
}

.card-content p {
  color: rgba(119, 124, 133, 0.89);
}
