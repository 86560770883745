.main-container {
  margin: 0;
  padding: 32px 44px;
  background-image: linear-gradient(198deg, #bba9e2aa 0%, #ffffff 100%);
  min-height: 800px;
}

.hero-section {
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 800px;
}

.hero-btn > .hero-btn-1 {
  margin: 12px 4px 1px 0px;
  padding: 12px 22px;
  font-size: 14px;
}

.hero-btn > .hero-btn-2 {
  margin: 12px 0px 1px 4px;
  padding: 12px 22px;
  font-size: 14px;
}

.hero-head {
  font-size: 50px;
  font-family: "Rubik", "sans-serif";
  font-weight: 700;
}

.left-hero-section,
.right-hero-section {
  margin: 44px 0px;
}

.left-hero-section p {
  font-size: 22px;
}

.hero-btn-1 {
  background-image: linear-gradient(
    to right,
    #9d50bb 0%,
    #6e48aa 51%,
    #9d50bb 100%
  );
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 6px;
}

.hero-btn-1:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

.hero-btn-2 {
  background-image: linear-gradient(
    to right,
    #9d50bb 0%,
    #6e48aa 51%,
    #9d50bb 100%
  );
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 6px;
}

.hero-btn-2:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

.hero-head p {
  color: rgb(93, 93, 93);
}

.text-gradient {
  font-weight: 800;
  color: darkblue;
}

@supports (--css: variables) {
  .text-gradient {
    background-image: linear-gradient(
      to right,
      #9d50bb 0%,
      #6e48aa 51%,
      #9d50bb 100%
    );
    color: transparent;
    background-clip: text;
  }

  .text-gradient::selection {
    color: white;
    background: darkorchid;
  }
}
