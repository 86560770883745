.reset-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reset-page h1 {
  color: rgb(53, 52, 52);
}

.reset-pass-form {
  border-radius: 12px;
  padding: 44px 32px;
  max-width: 600px;
}

.reset-page-heading {
  margin-bottom: 32px;
}

.reset-page-heading h1 {
  font-weight: 600;
}

.forgot-password {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.forgot-password label {
  font-size: 18px;
  color: rgb(23, 22, 22);
  margin-bottom: 0;
}

.forgot-password input {
  padding: 4px 4px;
  outline: none;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid darkviolet;
  font-size: 18px;
  color: rgb(68, 67, 67);
  margin-top: 0;
  caret-color: rgb(127, 9, 187);
  width: 100%;
}

.forgot-pass-btn button {
  background-image: linear-gradient(
    to right,
    #9d50bb 0%,
    #6e48aa 51%,
    #9d50bb 100%
  );
  padding: 12px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 6px;
  outline: 1px solid white;
  border: none;
  text-transform: capitalize;
  margin: 44px 0px 12px 0px;
}

.forgot-pass-btn button:hover {
  background-position: right center;
}
