.cart-page-container {
  min-height: 80vh;
  /* color: rgb(255, 255, 255); */
  width: 100%;
}

@media only screen and (max-width : 475px) {
  .cart-card {
    flex-wrap: wrap;
  }

  .cart-card img {
    width: 100% !important;
    height: 100% !important;
  }
}

/* @media only screen and (max-width: 998px) {
  .flex-cart {
    justify-content: start !important;
  }
} */

.cart-card img {
  width: 30rem;
  height: 140px;
}