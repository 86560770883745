.business-problem-head .prob-main-head {
  color: #a87fff;
  font-weight: 600;
}

.business-prob-container {
  padding: 44px 22px;
  margin: 0px 0px;
}

.business-problem-head {
  line-height: 52px;
}

.business-prob-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 34px;
  margin: 44px 0px;
}

.business-prob-cards .prob-card {
  width: 350px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  display: flex;
  flex-direction: column;
  padding: 34px 22px;
  box-sizing: border-box;
}

.business-prob-cards .prob-card .card-head {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  flex-direction: row;
  margin: 12px 0px;
}

.business-prob-cards .prob-card .card-head .card-head-text {
  font-weight: bold;
  /* color: #6674cc; */
}

.business-prob-cards .prob-card .card-head .card-head-icon {
  color: #a87fff;
}

.card-badge {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
}

.card-badge #card-badge-1 {
  font-size: 20px;
}

.card-badge #card-badge-2 {
  font-size: 20px;
}

.card-badge .badge {
  padding: 6px 22px;
  color: white;
  /* background-color: rgb(0, 208, 128) !important; */
}

.card-badge .badge-color-1 {
  background-image: linear-gradient(
    to right,
    #5c258d 0%,
    #4389a2 51%,
    #5c258d 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;
}

.card-badge .badge-color-1:hover,
.card-badge .badge-color-2:hover {
  background-position: right center;
}

.card-badge .badge-color-2 {
  background-image: linear-gradient(
    to right,
    #16222a 0%,
    #3a6073 51%,
    #16222a 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;
}

@media only screen and (max-width: 1755px) {
  .business-prob-section {
    background: none;
  }
}

.card-content p {
  color: rgba(119, 124, 133, 0.89);
}

.btn-grad {
  background-image: linear-gradient(
    to right,
    #005c97 0%,
    #363795 51%,
    #005c97 100%
  );
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
