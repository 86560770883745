@media (max-width: 410px) {
  .my-order-card {
    padding-left: 12px !important;
    padding-right: 12px !important;
    font-size: 0.8rem !important;
  }
}

.my-order-card {
  padding-left: 1rem;
  padding-right: 1rem;
}

.order-details-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  align-items: center;
}

.order-detail {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .order-details-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .order-details-grid {
    grid-template-columns: 1fr;
    text-align: left;
  }

  .order-detail {
    white-space: normal;
    display: flex;
    justify-content: space-between;
  }
}
