.profile-image {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 18px;
}

.profile-image img {
  width: 300px;
  max-height: 300px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
}

.update-profile-image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.profile-info-content {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.user-profile-info .info-row p,
.user-social-info .info-row div {
  margin: 0;
  letter-spacing: 0px;
  font-size: 18px;
  color: rgb(52, 51, 51);
}

.user-profile-info .info-row p span,
.user-social-info .info-row p span {
  color: black;
}

.social-link-input {
  width: 90%;
  outline: none;
  border: 1px solid rgb(187, 184, 184);
  border-radius: 4px;
  padding: 2px 9px;
  caret-color: rgb(124, 122, 122);
  font-weight: 500;
}

.social-link-icon a{
  font-size: 24px;
  color: #7b56ca;
  padding: 2px 4px;
}

.social-link-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.user-profile-btns {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
