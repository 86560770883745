.bg-black-gradient {
  background: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-black-gradient-2 {
  background: linear-gradient(
    -168.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-gray-gradient {
  /* background: linear-gradient(
    153.47deg,
    rgba(255, 255, 255, 0) -341.94%,
    #14101d 95.11%
  ); */

  background-image: linear-gradient(198deg, #cdbbf9aa 0%, #ffffff 100%);
}

.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.register-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4em;
}

.register-left {
  max-width: 50em;
  padding: 22px;
  margin: 0px 22px;
}

.register-left img {
  max-height: 25em;
  width: 100%;
}

.register-right {
  max-width: 50em;
}

.register-card {
  padding: 22px;
}

.register-card-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.register-card-header .card-head h2 {
  margin: 0;
}

@media (min-width: 0) and (max-width: 576px) {
  /* .register-left, .register-right {
    padding: 44px 28px;
  } */

  .register-page {
    gap: 0px;
  }

  .register-left {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .register-left {
    display: none;
  }
}