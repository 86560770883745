* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  background-image: linear-gradient(
    to right,
    #9d50bb 0%,
    #6e48aa 51%,
    #9d50bb 100%
  );
  color: #fff;
  padding: 0;
}

.navbar-brand img {
  width: 44px;
  height: 44px;
}

.nav-link {
  font-size: 18px;
  color: white !important;
}

.register-btn {
  background-image: linear-gradient(
    to right,
    #9d50bb 0%,
    #6e48aa 51%,
    #9d50bb 100%
  );
  padding: 10px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 6px;
  outline: 1px solid white;
}

.register-btn:hover {
  background-position: right center;
}

.user-profile {
  /* display: inline-block !important ; */
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  font-weight: bold;
  font-size: 28px;
  object-fit: cover;
  /* background-color: #6e48aa;
  background-color: #9d50bb; */
}

/*! OffCanvas  */
.offCanvasBody {
  list-style: none;
  padding: 0;
}

.offCanvasBody ul {
  list-style: none;
  padding: 0;
}

.offCanvasBody ul li {
  font-size: 18px;
  margin-bottom: 12px;
}

.offCanvasBody ul li a {
  color: black;
}

.sidebar-profile .profile-info {
  font-size: 16px;
}

.sidebar-btn {
  background-image: linear-gradient(
    to right,
    #9d50bb 0%,
    #6e48aa 51%,
    #9d50bb 100%
  );
  padding: 10px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 6px;
}

.sidebar-btn:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

.sidebar-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
