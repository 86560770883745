.about-container {
  padding: 0;
  background-image: linear-gradient(198deg, #bba9e2aa 0%, #ffffff 100%);
}

.about-banner-section {
  min-height: 700px;
  color: rgb(255, 255, 255);
  width: 100%;
}

.about-banner-right img {
  width: 100%;
  height: 400px;
}

.about-banner-left p {
  font-size: 2.8rem;
  line-height: 50px;
}

.about-banner-left {
  padding: 0px 2vw;
}

.about-us-container {
  flex-wrap: wrap;
  flex-direction: row;
}

.about-us-bg {
  background-image: url(../assets/images/about/about-us-bg.webp);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;
  background-size: cover;
  background-attachment: scroll;
}

.about-us-content {
  text-align: justify;
  text-justify: inter-word;
  line-height: 26px;
  margin: 12px 0px;
}

@media only screen and (max-width: 400px) {
  .about-banner-left p {
    font-size: 11vw;
  }

  .about-banner-section {
    gap: 0px !important;
  }
}

/*! Founders Section */

.founders-section {
  min-height: 645px;
  width: 100%;
}

.founders-card {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 14px 18px;
}

.founder-profile {
  display: flex;
  align-items: center;
  gap: 22px;
}

.founder-profile .left img {
  border-radius: 50%;
  max-height: 130px;
} 

.founder-profile .right {
  display: flex;
  flex-direction: column;
}

.founder-profile .right p {
  margin: 0;
  color: rgb(30, 29, 29);
  font-weight: bold;
}

.founder-profile .right span {
  font-style: italic;
}

.founder-profile .right .founder-icons {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 8px 0px 0px 0px;
}

.founder-profile .right .founder-icons .founder-icon{
  font-size: 14px;
  color: white;
  margin-bottom: 0;
}

.founder-about {
  padding: 6px;
}

.founder-about p {
  font-size: 16px;
}