.trainingHomeCardContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;
    text-align: center;
    margin: 66px 0px 44px 0px;
  }
  
  .trainingHomeCard {
    width: 350px;
    padding: 44px 44px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    display: flex;
    flex-direction: column;
    transition: all;
    transition-duration: 0.3s;
    transition-timing-function: linear;
    animation: rainbow 3s linear infinite;
  }
  
  /* rgb(72 46 95 / 25%) 0px 13px 54px 8px, rgb(67 12 117 / 55%) 0px 8px 21px 3px */
  
  .trainingHomeCard:hover {
    transform: scale(1.05) !important;
  }
  
  .trainingHomeCard h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 12px 0px;
  }
  
  .trainingHomeCard p {
    font-size: 15px;
    font-family: "poppins", "sans-serif";
    color: black;
    margin: 12px 0px;
  }
  
  .trainingHomeCard .icon {
    width: 66px;
    height: 66px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 22px;
    margin: 12px auto;
  }
  
  .whyUsSection {
    background: url(../assets/images/home/bg-section.webp);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    background-size: cover;
    background-attachment: scroll;
    border: rgb(89, 191, 255);
  }
  
  .whyUsHeading {
    line-height: 52px;
    margin-bottom: 80px;
  }
  
  .card-content p {
    color: rgba(119, 124, 133, 0.89);
  }

  @keyframes rainbow {
    0% {
      border-color: red;
    }
    14% {
      border-color: orange;
    }
    28% {
      border-color: yellow;
    }
    42% {
      border-color: green;
    }
    57% {
      border-color: blue;
    }
    71% {
      border-color: indigo;
    }
    85% {
      border-color: violet;
    }
    100% {
      border-color: red;
    }
  }